/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/assets/style/layout.css"

import React from "react"
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3"
import { AuthProvider } from "./src/context/userContext"
import {
  positions,
  transitions,
  Provider as AlertProvider,
  useAlert,
} from "react-alert"
import { X } from "react-feather"
import { Flex, Text } from "theme-ui"

export const wrapRootElement = ({ element }) => {
  const alertOptions = {
    timeout: 10000,
    transition: transitions.FADE,
  }
  const CustomAlertTemplate = ({ style, options, message, close }) => {
    const alert = useAlert()

    const handleClick = () => {
      alert.removeAll()
      close()
    }

    return (
      <div
        style={{
          ...style,
          width: "400px",
          backgroundColor: "#ffffff",
          padding: "10px",
          borderRadius: "5px",
          boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Flex sx={{ justifyContent: "space-between", alignItems: "center" }}>
          <Text
            sx={{
              fontSize: "15px",
              fontFamily:
                '"roc-grotesk", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", sans-serif',
            }}
          >
            {message}
          </Text>
          <X size={"24"} color={"#0098D7"} onClick={handleClick} />
        </Flex>
      </div>
    )
  }
  return (
    <AlertProvider
      template={CustomAlertTemplate}
      position={positions.TOP_CENTER}
      {...alertOptions}
    >
      <GoogleReCaptchaProvider reCaptchaKey="6LcKscAqAAAAAFVKuWpqUvB9h3sCDf-QdByieBpJ">
        <AuthProvider>{element}</AuthProvider>
      </GoogleReCaptchaProvider>
    </AlertProvider>
  )
}
